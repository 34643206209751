@use '@angular/material' as mat;

@import 'https://fonts.googleapis.com/icon?family=Material+Icons';
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100&display=swap');

@include mat.elevation-classes();
@include mat.app-background();

$fontConfig: mat.m2-define-typography-config(
    $font-family: 'Roboto, "Helvetica Neue", sans-serif',
    $headline-1: mat.m2-define-typography-level(112px, 112px, 300, 'Roboto', -0.05em),
    $headline-2: mat.m2-define-typography-level(56px, 56px, 300, 'Roboto', -0.05em),
    $headline-3: mat.m2-define-typography-level(45px, 48px, 400, 'Roboto', 0.05em),
    $headline-4: mat.m2-define-typography-level(34px, 40px, 400, 'Roboto', 0.05em),
    $headline-5: mat.m2-define-typography-level(24px, 32px, 400, 'Roboto', 0.05em),
    $headline-6: mat.m2-define-typography-level(20px, 32px, 500, 'Roboto', 0.05em),
    $subtitle-1: mat.m2-define-typography-level(16px, 28px, 400, 'Roboto', 0.0094em),
    $subtitle-2: mat.m2-define-typography-level(15px, 24px, 500, 'Roboto', 0.0067em),
    $body-1: mat.m2-define-typography-level(14px, 24px, 400, 'roboto', 0.0179em),
    $body-2: mat.m2-define-typography-level(14px, 24px, 500, 'roboto', 0.0179em),
    $caption: mat.m2-define-typography-level(12px, 20px, 400, 'Roboto', 0.0333em),
    $button: mat.m2-define-typography-level(14px, 14px, 500, 'Roboto', 0.0893em),
);

$theme: mat.m2-define-light-theme((
    color: (
        primary: mat.m2-define-palette(mat.$m2-blue-gray-palette, 500, 300, 700),
        accent: mat.m2-define-palette(mat.$m2-blue-grey-palette), //mat.m2-define-palette(mat.$orange-palette, 100, 100, 70),
        //warn: mat.m2-define-palette(mat.$red-palette), //, 500, 300, 700),
        background: mat.m2-define-palette(mat.$m2-grey-palette, 50, 100, 900)
    ),
    typography: $fontConfig
));

@include mat.all-component-themes($theme);


html, body {
    height: 100%;
}

body {
    margin: 0;
    font-family: Roboto, "Helvetica Neue", sans-serif;
}

.dark-backdrop {
    background: rgba(0, 0, 0, 0.15);
}

.ml-25 {
    margin-left: 25px;
}

.mb-15 {
    margin-bottom: 15px;
}

.w-100p {
    width: 100%;
}

.w-85p {
    width: 85%;
}

.w-65p {
    width: 65%;
}

.w-50p {
    width: 50%;
}

.w-25p {
    width: 25%;
}

.w-15p {
    width: 15%;
}

.mt-15 {
    margin-top: 25px;
}

.mb-25 {
    margin-bottom: 25px;
}

.mb-50 {
    margin-bottom: 50px;
}

.mr-10 {
    margin-right: 10px;
}

.form-wrapper {
    display: grid;
    grid-template-columns: auto;
}

mat-form-field {
    margin-bottom: 20px;
}

.mdc-form-field {
    width: 100%;
}

.editor {
    height: 200px;
    margin-bottom: 30px;
}

.button-group.mat-mdc-raised-button {
    width: 400px;
    margin: 15px;
}

.mat-expansion-panel-header-description {
    justify-content: end;
}

.separator {
    padding-top: 25px;
    margin-bottom: 25px;
}

mat-form-field {
    width: 100%;
}

.toggle-container {
    display: flex;
    flex-direction: column;
}

mat-slide-toggle {
    margin-top: 5px;
    margin-bottom: 5px;
    display: block;
}

mat-card {
    margin-top: 5px;
    margin-bottom: 25px;
}

mat-card-content {
    margin-top: 25px;
}

mat-accordion {
    margin-top: 25px;
    margin-bottom: 25px;
}

mat-divider {
    margin-bottom: 25px;
}

.mat-mdc-table {
    background-color: transparent;
}

.instructions {
    margin-bottom: 25px;
}

.subsection {
    display: grid;
    grid-template-columns: auto;
    margin-bottom: 50px;
}

.subsection:last-child {
    margin-bottom: 0;
}

.subsection-title {
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 25px;
}

.guidance-btn {
    width: 8px;
    height: 8px;
    font-size: 14px;
}

.mat-badge-medium.mat-badge-above .mat-badge-content {
    top: unset;
    right: unset;
}
[data-amplify-authenticator] {
    --amplify-components-button-primary-background-color: #607d8b;
    --amplify-components-button-primary-hover-background-color: #6596ad;
    --amplify-components-button-primary-focus-background-color: #6596ad;
    --amplify-components-button-primary-active-background-color: #607d8b;
    --amplify-components-tabs-item-active-color: #607d8b;
    --amplify-components-tabs-item-color: #607d8b;
    --amplify-components-button-link-color: #607d8b;
    --amplify-components-button-link-hover-color: #6596ad;
    --amplify-components-button-link-focus-color: #6596ad;
    --amplify-components-button-link-active-color: #607d8b;
    --amplify-components-button-link-active-background-color: transparent;
    --amplify-components-button-link-focus-background-color: transparent;
    --amplify-components-button-link-hover-background-color: transparent;
}
//[data-amplify-authenticator] {
//    --amplify-components-authenticator-router-box-shadow: 0 0 16px var(--amplify-colors-overlay-10);
//    --amplify-components-authenticator-router-border-width: 0;
//    --amplify-components-authenticator-form-padding: var(--amplify-space-medium) var(--amplify-space-xl) var(--amplify-space-xl);
//    --amplify-components-button-primary-background-color: #607d8b;
//    --amplify-components-fieldcontrol-focus-box-shadow: 0 0 0 2px #607d8b;
//    --amplify-components-tabs-item-active-border-color: #607d8b;
//    --amplify-components-tabs-item-color: #607d8b;
//    --amplify-components-tabs-item-active-color: #607d8b;
//    --amplify-components-button-link-color: #607d8b;
//}
//.amplify-button--primary {
//    background: #607d8b;
